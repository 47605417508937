import React, { useContext, useEffect, useState } from 'react'
import Products from '../../components/complex/Products'
import { Context } from '../..';
import DroupdownLang from '../../components/ui/DroupdownLang';

const QrMenu = () => {
    const {app} = useContext(Context);

    const [logo, setLogo] = useState();

    useEffect(() => {
       document.body.className = 'qr-menu'
        return () => {
            document.body.classList.remove("qr-menu");
        };
    }, [])
    
    useEffect(() => {
        if(app.settings.length >= 1){
            setLogo(app.settings.find(it => it.type === "logo"));
        }
    }, [app.settings])

  return (
    <div>
        <div className='qr-menu-header'>
            <img src={logo?.value} alt="" />
            <DroupdownLang/>
        </div>
        <Products/>
    </div>
  )
}

export default QrMenu