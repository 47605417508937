import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import Utils from '../../services/utils';

const PresentModal = observer(({show, setShow, remainder, isFull, progress, presentPromocode}) => {
    const {app} = useContext(Context);

    const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose} centered className='delivery-stock-modal'>
        <Modal.Body className={isFull ? 'delivery-stock-content present-stock present-stock-full' : 'delivery-stock-content present-stock'}>
            <i className='icon-close' onClick={handleClose}></i>
            <div className='delivery-stock-top'>
                    <div className='delivery-stock-title'>
                        <div className='output' style={{ clipPath: `polygon(0% 0%, ${progress}% 0, ${progress}% 100%, 0% 100%)` }}></div>
                        <div className='output-inner'></div>
                        <div className='inner'><i className='icon-present'></i></div>
                    </div>
                    {isFull ?
                        <div>
                            <div className='delivery-stock-top-title'>{app.localizationsItems?.present_title}</div>
                            <p>{app.localizationsItems?.get_present}</p>
                        </div>
                        :
                        <div>
                            <div className='delivery-stock-top-title'>{app.localizationsItems?.present_more} {Utils.numberWithSpaces(remainder)} {app.localizationsItems?.currency}</div>
                            <p>{app.localizationsItems?.to_present}</p>
                        </div>
                    }
            </div>
            <div>
                <div className='delivery-stock-item'><b>{app.localizationsItems?.present_from_price} {Utils.numberWithSpaces(presentPromocode?.min_price)} {app.localizationsItems?.currency}</b></div>
                {presentPromocode?.products?.map((card, index) =>
                    <div className='delivery-stock-item' key={index}>{card.title}</div>
                )}
            </div>
            <button className='main-btn' onClick={handleClose}>{app.localizationsItems?.sure}</button>
        </Modal.Body>
    </Modal>
  )
})

export default PresentModal