import React, { useContext } from 'react'
import Slider from 'react-slick'
import useFetchItems from '../../hooks/useFetchItems';
import CatalogServices from '../../services/general/catalogServices';
import ProductsLoading from '../simple/ProductsLoading';
import RecommendedProductCard from '../simple/RecommendedProductCard';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const PopularProducts = observer(() => {
    const {app} = useContext(Context);

    const { 
        items,
        loading
    } = useFetchItems(CatalogServices.products.index, `type=recomended`);

    const settings = {
        arrows: true,
        infinite: false,
        slidesToShow: 7,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 1400,
            settings: {
                slidesToShow: 6,
            }
        },
        {
            breakpoint: 996,
            settings: {
                slidesToShow:5,
            }
        },
        {
            breakpoint: 757,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 3,
                
            }
        },

    ]
    }

  return (
    <>
        {loading ?
            <ProductsLoading/>
            :
            <div>
                <div className="container">
                    <h2 className='mb-30'>{app.localizationsItems?.recomended_products}</h2>
                </div>
                {items?.items?.length >=1 && 
                    <div className="slider-container">
                        <Slider {...settings} className='recent-slider'>
                            {items.items.map((card, index) =>
                                <RecommendedProductCard card={card} key={index}/>
                            )}
                        </Slider>
                    </div>
                }
            </div>
        }
    </>
  )
})

export default PopularProducts