import React, { useContext, useState } from 'react'
import img from "../../images/placeholder.png"
import Utils from '../../services/utils'
import ProductViewModal from '../modals/ProductViewModal';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const RecommendedProductCard = observer(({card}) => {
    const {app} = useContext(Context);

    const [show, setShow] = useState();

  return (
    <>
        <div className='recommended-card' onClick={() => setShow(true)}>
            <img src={card?.image ? card?.image : img} alt="" />
            <div className='recommended-card-title'>{card?.title ? card?.title : 'Ролл Золотой дракон'}</div>
            {card?.old_price ? <div className='recommended-card-old-price'>{Utils.numberWithSpaces(card?.old_price ? card.old_price : 300)} {app.localizationsItems?.currency}</div> : <></>}
            <div className='recommended-card-price'>
                {Utils.numberWithSpaces(card?.price ? card.price : 300)} {app.localizationsItems?.currency}
                {/*<i className='icon-plus'></i>*/}
            </div>
        </div>
        {show && <ProductViewModal show={show} setShow={setShow} slug={card.slug}/>}
    </>
  )
})

export default RecommendedProductCard