import React, { useContext, useEffect, useState } from 'react'
import Utils from '../../services/utils';
import { Context } from '../..';

const PresentCart = ({total, presentPromocode}) => {
    const {app} = useContext(Context);
    const [progress, setProgress] = useState(0);
    const [isFull, setIsFull] = useState();
    const [remainder, setRemainder] = useState();

    useEffect(() => {
        if(presentPromocode?.min_price <= total){
            setProgress(100);
            setRemainder(null);
            setIsFull(true)
        }else{
            let tempProgress = (total*100)/Number(presentPromocode?.min_price);
            setProgress(tempProgress);
            setRemainder(Number(Number(presentPromocode?.min_price)-total));
            setIsFull(false)
        }
    }, [total])

  return (
    <>{isFull ?
        <></>
        :
        <div className='present-stock'>
            <div className='delivery-stock-top'>
                <div className='delivery-stock-title'>
                    <div className='output' style={{ clipPath: `polygon(0% 0%, ${progress}% 0, ${progress}% 100%, 0% 100%)` }}></div>
                    <div className='output-inner'></div>
                    <div className='inner'><i className='icon-present'></i></div>
                </div>
                <div>
                    <div className='delivery-stock-top-title'>{app.localizationsItems?.present_more} {Utils.numberWithSpaces(remainder)} тг</div>
                    <p>{app.localizationsItems?.to_present}: 
                        {presentPromocode?.products?.map((card, index) =>
                            <span key={index}> {card.title}, </span>
                        )}
                    </p>
                </div>
            </div>
        </div>}
    </>
  )
}

export default PresentCart