import React, { useContext, useEffect, useState } from 'react'
import { Offcanvas } from 'react-bootstrap';
import CartProduct from '../simple/CartProduct';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import CartSummary from '../simple/CartSummary';
import { FormProvider, useForm } from 'react-hook-form';
import RecommendedProducts from '../complex/RecommendedProducts';
import CartDevices from '../simple/CartDevices';
import CartDelivery from '../complex/CartDelivery';
import CartPayment from '../complex/CartPayment';
import img from "../../images/emptyBasket.svg"
import PresentProductCard from '../simple/PresentProductCard';
import ClearBasketModal from './ClearBasketModal';
import PresentCart from '../complex/PresentCart';
import DeliveryCart from '../complex/DeliveryCart';
import { toast } from 'react-toastify';
import UserServices from '../../services/general/userServices';
import { useNavigate } from 'react-router-dom';
import LoginModal from './LoginModal';
import LoginCodeModal from './LoginCodeModal';
import { emptycard } from '../ui/StaticImages';
import RegistrationModal from './RegistrationModal';
import ForgotPasswordModal from './ForgotPasswordModal';

const CartModal = observer(({show, setShow, deliveryPromocode, presentPromocode}) => {
    const {app, user} = useContext(Context)

    const handleClose = () => setShow(false);

    const [loading, setLoading] = useState();

    const navigate = useNavigate();

    const [deviceCount, setDeviceCount] = useState(0);
    const [deleteModal, setDeleteModal] = useState();

    const [presentProducts, setPresentProducts] = useState();
    const [deliveryStock, setDeliveryStock] = useState();
    const [total, setTotal] = useState();

    const [registrationModal, setRegistrationModal] = useState();
    const [forgotPasswordModal, setForgotPasswordModal] = useState();
    const [updatePasswordModal, setUpdatePasswordModal] = useState();
    const [sendCodeModal, setSendCodeModal] = useState();

    const [email, setEmail] = useState();
    const [code, setCode] = useState();

    const [modalShow, setModalShow] = useState();
    const [codeShow, setCodeShow] = useState();
    const [phone, setPhone] = useState();

    const [payment, setPayment] = useState();

    const [bonusUse, setBonusUse] = useState();

    const [promocode, setPromocode] = useState('');
    const [promocodeInfo, setPromocodeInfo] = useState();

    const [deliveryInZones, setDeliveryInZones] = useState(false);

    const [description, setDesctiption] = useState('');

    const [orderData, setOrderData] = useState();

    const [date, setDate] = useState({day: app.localizationsItems?.near_future});

    const methods = useForm();

    useEffect(() => {
        if(user.cartProducts){
            let temp = 0;
            for(let i = 0; i < user.cartProducts.length; i++){
                temp += Number(user.cartProducts[i]?.price) * Number(user.cartProducts[i].count);
            }
            setTotal(temp)
            if(presentPromocode){
                if(temp >= Number(presentPromocode.min_price)){
                    setPresentProducts(presentPromocode.products);
                }else{
                    setPresentProducts(null)
                }
            }
            if(deliveryPromocode){
                if(temp >= Number(deliveryPromocode.min_price)){
                    setDeliveryStock(true);
                }else{
                    setDeliveryStock(null)
                }
            }
        }
    }, [user.cartProducts])

    const CustomSubmit = async (data) => {
        if(!user.address){
            toast.error(app.localizationsItems?.delivery_select)
            return
        }

        /*if(!deliveryInZones){
            toast.error(app.localizationsItems?.address_no_zone);
            return
        }*/
        setLoading(true);

        if(!payment){
            toast.error(app.localizationsItems?.select_payment)
            return
        }
        const {bonus_used, ...rest} = data;

        const newData = {
            cutlery_amount: deviceCount,
            uid: user.uid,
            bonus_used: bonusUse ? user.balance : 0,
            payment_id: payment?.payment_id,
            delivery_id: user?.address?.delivery_id,
            address_id: user?.address?.pickup_id,
            delivery_date: date,
            promocode: promocode,
            full_address: user?.address?.full_address,
            json_address: user?.address?.json_address,
            description: description,
            ...rest
        }

        if(!user.token){
            setModalShow(true);
            setOrderData(newData);
        }else{
            const res = await UserServices.cart.order.order(newData);

            if(res.statusCode === 200){
                user.removeUid();
                user.setBasketChange(Math.random().toString(16));
    
                setShow(false)
    
                if(res.content.checkout_url){
                    window.open(res.content.checkout_url, '_self');
                }else{
                    toast.success(app.localizationsItems?.order_success);
                    navigate(`/${app.lang}/user/orders`)
                }
            }else if(res.statusCode === 502){
                user.removeUid();
                user.setBasketChange(Math.random().toString(16));
                navigate(`/${app.lang}/order-error`)
            }
            else{
                toast.error(res.message)
            }
        }

        setLoading(false);
    }

  return (
      <Offcanvas show={show} onHide={handleClose} placement={'end'} className='cart-offcanvas'>
        <Offcanvas.Header closeButton>
            <Offcanvas.Title>{app.localizationsItems?.cart}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            <FormProvider {...methods}>
                {user.cartProducts?.length >= 1 ?
                    <form className='cart-container' onSubmit={methods.handleSubmit(CustomSubmit)}>
                        {user.cartProducts?.map((card, index) =>
                            <CartProduct card={card} key={index}/>
                        )}
                        {promocodeInfo?.products?.length >= 1 ?
                            promocodeInfo?.products.map((card, index) =>
                                <PresentProductCard card={card} key={index}/>
                            )
                            : !promocodeInfo && presentProducts?.length >= 1 ?
                                presentProducts?.map((card, index) =>
                                    <PresentProductCard card={card} key={index}/>
                            )
                            :
                            <></>
                        }
                        <div className='clear-cart' onClick={() => setDeleteModal(true)}>
                            <i className='icon-trash'></i>
                            {app.localizationsItems?.clear_basket}
                        </div>
                        {presentPromocode && <PresentCart total={total} presentPromocode={presentPromocode}/>}
                        {deliveryPromocode && <DeliveryCart total={total} deliveryPromocode={deliveryPromocode}/>}

                        <RecommendedProducts/>

                        <CartDevices setDeviceCount={setDeviceCount} deviceCount={deviceCount}/>

                        <CartDelivery setDate={setDate} date={date} description={description} setDesctiption={setDesctiption}/>
                        <CartPayment bonusUse={bonusUse} 
                            setBonusUse={setBonusUse} 
                            promocode={promocode} 
                            setPromocode={setPromocode} 
                            total={total} 
                            setPromocodeInfo={setPromocodeInfo}
                            payment={payment} setPayment={setPayment}
                            promocodeInfo={promocodeInfo}
                        />

                        <label className='product-checkbox'>
                            <input type="checkbox" {...methods.register('disallow_to_recall')}/>
                            <span>{app.localizationsItems?.not_call}</span>
                        </label>
                        <CartSummary setShow={setShow} deliveryStock={deliveryStock} bonusUse={bonusUse} promocodeInfo={promocodeInfo} setDeliveryInZones={setDeliveryInZones}/>
                    </form>
                    :
                    <div className='empty-cart'>
                        {emptycard}
                        <p>{app.localizationsItems?.empty_cart}</p>
                        <div className='main-btn' onClick={handleClose}>{app.localizationsItems?.to_main}</div>
                    </div>
                }
            </FormProvider>
        </Offcanvas.Body>
        {deleteModal && 
            <ClearBasketModal show={deleteModal} setShow={setDeleteModal}/>
        }
        {modalShow &&
            <LoginModal 
                show={modalShow} 
                setShow={setModalShow} 
                setCodeShow={setCodeShow} 
                setPhone={setPhone}
                setRegistrationModal={setRegistrationModal}
                setForgotPasswordModal={setForgotPasswordModal}
            />
        }
        {codeShow && 
            <LoginCodeModal show={codeShow} setShow={setCodeShow} phone={phone}/>
        }
        {registrationModal &&
            <RegistrationModal
                show={registrationModal}
                setShow={setRegistrationModal}
            />
        }
        {forgotPasswordModal &&
            <ForgotPasswordModal
                show={forgotPasswordModal}
                setShow={setForgotPasswordModal}
                setSendCodeModal={setSendCodeModal}
                setEmail={setEmail}
            />
        }
      </Offcanvas>
  )
})

export default CartModal